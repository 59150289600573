export const REFUND_MODAL_ID = 'refund';
export const PAYMENT_DETAIL_SHEET_ID = 'payment-details';
export const NATIVE_APP_SHEET_ID = 'native-app-sheet';
export const DELETE_NATIVE_APP_MODAL_ID = 'delete-native-app';
export const ADD_ACCOUNT_SHEET_ID = 'add-account';
export const DELETE_ACCOUNT_MODAL_ID = 'delete-account';
export const LOSE_CHANGES_MODAL_ID = 'lose-changes';
export const FILTER_PAYMENTS_SHEET_ID = 'filter-transactions';
export const ACTION_MODAL_ID = 'action';
export const FILTER_RESET_MODAL_ID = 'filter-reset';
export const SECRET_KEY_SHEET_ID = 'secret-key-sheet';
export const CREATE_MERCHANT_SHEET_ID = 'create-merchant-sheet';
export const WEBHOOK_SHEET_ID = 'webhook-sheet';
export const DISABLE_WEBHOOK_MODAL_ID = 'disable-webhook';
export const DELETE_WEBHOOK_MODAL_ID = 'delete-webhook';
export const DUPLICATE_PAYMENT_SHEET_ID = 'duplicate-payment';
export const DUPLICATE_PAYMENT_MODAL_ID = 'duplicate-payment-modal';
export const APPLE_PAY_MODAL_ID = 'apple-pay-modal';
